import { useEffect } from 'react';
import { usePresence } from 'framer-motion';

export const usePresenceRemoval = () => {
    const [isPresent, safeToRemove] = usePresence();

    useEffect(() => {
        if (!isPresent) {
            safeToRemove();
        }
    }, [isPresent]);

    return isPresent;
};
