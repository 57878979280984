import { AiOutlineWechat } from 'react-icons/ai';
import { FiMail, FiShare2 } from 'react-icons/fi';
import {
    SiBandsintown,
    SiSmugmug,
    SiVsco,
    SiTiktok,
    SiDeezer,
    SiDiscord,
    SiSlack,
    SiLine,
    SiAppstore,
    SiGoogledrive,
    SiUntappd,
    SiThreads,
    SiTripadvisor,
    SiX,
} from 'react-icons/si';
import { RiFacebookFill } from 'react-icons/ri';
import {
    Fa500Px,
    FaBehance,
    FaCodepen,
    FaDribbble,
    FaDropbox,
    FaFlickr,
    FaFoursquare,
    FaGithub,
    FaGooglePlay,
    FaGooglePlusG,
    FaInstagram,
    FaItunesNote,
    FaLinkedinIn,
    FaMediumM,
    FaMeetup,
    FaPinterestP,
    FaReddit,
    FaRss,
    FaSnapchat,
    FaSoundcloud,
    FaSpotify,
    FaSquarespace,
    FaTumblr,
    FaTwitch,
    FaTwitter,
    FaVimeoV,
    FaVk,
    FaWhatsapp,
    FaYelp,
    FaYoutube,
    FaTelegramPlane,
} from 'react-icons/fa';

/**
 * Special case for email icon to avoid duplicated code.  Both e-mail and mailto share
 * the same icon. This allows existing users to use 'email' as an icon, while enabling
 * the existing logic to use the email icon when "mailto:" is present in the url.
 */

const EMAIL_ICON = {
    icon: FiMail,
    color: '#7f7f7f',
    alias: 'email',
};

export default {
    fivehundredpix: {
        icon: Fa500Px,
        color: '#222222',
    },
    bandsintown: {
        icon: SiBandsintown,
        color: '#1B8793',
    },
    behance: {
        icon: FaBehance,
        color: '#007CFF',
    },
    codepen: {
        icon: FaCodepen,
        color: '#151515',
    },
    dribbble: {
        icon: FaDribbble,
        color: '#ea4c89',
    },
    dropbox: {
        icon: FaDropbox,
        color: '#1081DE',
    },
    email: EMAIL_ICON,
    facebook: {
        icon: RiFacebookFill,
        color: '#3b5998',
    },
    flickr: {
        icon: FaFlickr,
        color: '#0063db',
    },
    foursquare: {
        icon: FaFoursquare,
        color: '#0072b1',
    },
    github: {
        icon: FaGithub,
        color: '#4183c4',
    },
    google_play: {
        icon: FaGooglePlay,
        color: '#40BBC1',
    },
    drive: {
        icon: SiGoogledrive,
        color: '#ff4030',
    },
    google: {
        icon: FaGooglePlusG,
        color: '#dd4b39',
        subnetworks: ['drive'],
    },
    instagram: {
        icon: FaInstagram,
        color: '#E1306C',
    },
    itunes: {
        icon: FaItunesNote,
        color: '#E049D1',
    },
    linkedin: {
        icon: FaLinkedinIn,
        color: '#007fb1',
    },
    mailto: EMAIL_ICON,
    medium: {
        icon: FaMediumM,
        color: '#333332',
    },
    meetup: {
        icon: FaMeetup,
        color: '#E51937',
    },
    pinterest: {
        icon: FaPinterestP,
        color: '#cb2128',
    },
    reddit: {
        icon: FaReddit,
        color: '#FF4500',
    },
    rss: {
        icon: FaRss,
        color: '#EF8733',
    },
    sharethis: {
        icon: FiShare2,
        color: '#00BF00',
    },
    smugmug: {
        icon: SiSmugmug,
        color: '#8cca1e',
    },
    snapchat: {
        icon: FaSnapchat,
        color: '#FFC91B',
    },
    soundcloud: {
        icon: FaSoundcloud,
        color: '#FF5700',
    },
    deezer: {
        icon: SiDeezer,
        color: '#6b42b4',
    },
    spotify: {
        icon: FaSpotify,
        color: '#2EBD59',
    },
    squarespace: {
        icon: FaSquarespace,
        color: '#1C1C1C',
    },
    tumblr: {
        icon: FaTumblr,
        color: '#2c4762',
    },
    twitch: {
        icon: FaTwitch,
        color: '#6441A5',
    },
    twitter: {
        icon: FaTwitter,
        color: '#00aced',
        alias: 'x',
    },
    x: {
        icon: SiX,
        color: '#111111',
    },
    vimeo: {
        icon: FaVimeoV,
        color: '#1ab7ea',
    },
    vk: {
        icon: FaVk,
        color: '#45668e',
    },
    vsco: {
        icon: SiVsco,
        color: '#83878A',
    },
    wechat: {
        icon: AiOutlineWechat,
        color: '#00c80f',
    },
    whatsapp: {
        icon: FaWhatsapp,
        color: '#25D366',
    },
    wa: {
        icon: FaWhatsapp,
        color: '#25D366',
        alias: 'whatsapp',
    },
    discord: {
        icon: SiDiscord,
        color: '#7289da',
    },
    slack: {
        icon: SiSlack,
        color: '#6ecadc',
    },
    line: {
        icon: SiLine,
        color: '#00c300',
    },
    yelp: {
        icon: FaYelp,
        color: '#B90C04',
    },
    youtube: {
        icon: FaYoutube,
        color: '#ff3333',
    },
    youtu: {
        icon: FaYoutube,
        color: '#ff3333',
        alias: 'youtube',
    },
    telegram: {
        icon: FaTelegramPlane,
        color: '#0088cc',
    },
    t: {
        icon: FaTelegramPlane,
        color: '#0088cc',
        alias: 'telegram',
    },
    tiktok: {
        icon: SiTiktok,
        color: '#010101',
    },
    apps: {
        icon: SiAppstore,
        color: '#1998f7',
    },
    untappd: {
        icon: SiUntappd,
        color: '#ffc000',
    },
    threads: {
        icon: SiThreads,
        color: '#444444',
    },
    tripadvisor: {
        icon: SiTripadvisor,
        color: '34e0a1',
    },
};
