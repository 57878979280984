import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

function configureStore(preloadedState) {
    return createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));
}

export default configureStore;
