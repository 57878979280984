import { useState, useEffect, useMemo } from 'react';

import { getThemeById } from '@mssgme/shared';

export const useDarkMode = () => {
    const [matcher] = useState(() => window.matchMedia?.('(prefers-color-scheme: dark)'));
    const [isDark, setMode] = useState(matcher?.matches);

    useEffect(() => {
        if (!matcher) {
            return;
        }

        const isOldListener = !matcher.addEventListener;
        const handler = (event) => {
            setMode(event.matches);
        };

        if (isOldListener) {
            matcher.addListener(handler);
        } else {
            matcher.addEventListener('change', handler);
        }

        return () => {
            if (isOldListener) {
                matcher.removeListener(handler);
            } else {
                matcher.removeEventListener('change', handler);
            }
        };
    }, [matcher]);

    return isDark;
};

export const useAutoTheme = (theme = { colorId: 'autoTheme', light: 'bwTheme', dark: 'wbTheme' }) => {
    const isDark = useDarkMode();

    return useMemo(() => {
        const isAuto = theme && theme.colorId.startsWith('autoTheme');
        const prefix = isDark ? 'dark' : 'light';
        const reversePrefix = isDark ? 'light' : 'dark';
        const resolved = (isAuto && theme[prefix]) || `${prefix}Theme`;
        const opposite = (isAuto && theme[reversePrefix]) || `${reversePrefix}Theme`;

        return {
            isAuto,
            theme: getThemeById(resolved),
            opposite: getThemeById(opposite),
        };
    }, [isDark, theme]);
};
