import { colorThemeFactory } from './colorTheme';

export const wbTheme = colorThemeFactory({
    id: 'wbTheme',
    name: 'White on Black',
    colors: {
        background: '#000000ff',
        panel: '#444444ff',
        text: '#ffffffff',
        dividerColor: '#555555ff',
        buttonBackground: '#ffffffff',
        buttonTextColor: '#333333ff',
        featureBackgroundColor: '#444444ff',
        featureTextColor: '#ffffffff',
        productsCategoryTextColor: '#ffffffff',
    },
});
