import PropTypes from 'prop-types';
import React from 'react';

import Logo from '../../../icons/logo.svg';

export function SplashScreen({ as: Tag = Logo }) {
    return (
        <div
            style={{
                width: 200,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <div style={{ width: '100%', filter: 'grayscale(1)', opacity: 0.1 }}>
                <Tag style={{ display: 'block', width: '100%', height: 'auto' }} />
            </div>
        </div>
    );
}

SplashScreen.propTypes = {
    as: PropTypes.elementType,
};
