import Link from './link';

export default class InstagramLink extends Link {
    getUrl(ua) {
        if (ua && ua.isMobile) {
            return this._name;
        }

        return `https://instagram.com/${this._name.replace(/^https:\/\/ig\.me\/m\//i, '')}`;
    }

    getPlatform() {
        return 'instagram';
    }
}
