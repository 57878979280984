import { colorThemeFactory } from './colorTheme';

export const tingeTheme = colorThemeFactory({
    id: 'tingeTheme',
    name: 'Tinge',
    colors: {
        background: '#edc8b8ff',
        panel: '#dcb7a7ff',
        text: '#000000ff',
        dividerColor: '#0000004d',
        buttonBackground: '#ab3b61ff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#dcb7a7ff',
        featureTextColor: '#000000ff',
        productsCategoryTextColor: '#000000ff',
    },
});
