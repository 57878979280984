import * as React from 'react';
import { IconBase } from 'react-icons';
function ShoppingCartIcon(props, svgRef) {
    return (
        <IconBase fill="none" viewBox="0 0 24 24" ref={svgRef} {...props}>
            <path
                d="M9 22a1 1 0 100-2 1 1 0 000 2zM20 22a1 1 0 100-2 1 1 0 000 2zM1 1h4l2.68 13.39a2 2 0 002 1.61h9.72a2 2 0 002-1.61L23 6H6"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </IconBase>
    );
}
const ForwardRef = React.forwardRef(ShoppingCartIcon);
export default ForwardRef;
