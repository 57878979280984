import { useEffect, useMemo } from 'react';
import { pick } from 'lodash';
import WebFont from 'webfontloader';

import { getThemeById, mergeTheme, useElementTheme } from '@mssgme/ui';
import { useAutoTheme } from './useDarkMode';

export const useResolvedTheme = (page) => {
    const { isAuto, theme: autoTheme } = useAutoTheme(page.theme);

    const resolvedTheme = useMemo(() => {
        return isAuto
            ? mergeTheme(autoTheme, {
                page: pick(page.theme.page, ['backgroundImage', 'backgroundImageOpacity', 'alignment']),
            })
            : mergeTheme(getThemeById(page.theme.colorId), page.theme);
    }, [isAuto, autoTheme, page.theme]);
    const { theme, style } = useElementTheme({
        baseTheme: resolvedTheme,
        elementPath: 'page',
        compile: true,
    });

    const align = useMemo(() => {
        const alignment =
            theme.alignment === 'bottom' ? 'flex-end' : theme.alignment === 'middle' ? 'center' : 'flex-start';

        return {
            container: { justifyContent: alignment },
            inner: {
                margin: alignment === 'center' ? 'auto 0' : alignment === 'flex-end' ? 'auto 0 0 0' : undefined,
            },
        };
    }, [theme.alignment]);

    useEffect(() => {
        if (theme?.font?.provider === 'google') {
            WebFont.load({
                classes: false,
                events: false,
                google: {
                    families: [`${theme.font.family}:300,400,500,600,700,900:latin,cyrillic`],
                },
            });
        }
    }, [theme?.font?.family, theme?.font?.provider]);

    return {
        theme,
        style,
        resolvedTheme,
        align,
    };
};
