import { useMemo } from 'react';

export const useWebsiteMenu = (website, menuItems) => {
    const enabled = !!website.menu?.enabled;
    const homePageId = website.homePageId;
    /**
     * @type {{ page: string }[]}
     */
    const items = menuItems || website.menu?.items;

    return useMemo(
        () => {
            if (!items) {
                return {
                    enabled,
                    patch: false,
                    items: [],
                };
            }

            const ids = website.pages.map((page) => page._id);
            const homepage = items.find(({ page }) => page === homePageId);
            const result = homepage ? [homepage] : [];
            let patch = false;

            for (const item of items) {
                if (item.page === homePageId) {
                    continue;
                }

                // Only include menu item once, the referenced page must exist
                // Fixes the bug where some users have duplicated menu items or
                // menu items, referencing deleted pages (after initial buggy PageMenu release)
                // todo: write db migration to fix bugged websites in the database and remove this after migration
                const idx = ids.indexOf(item.page);

                if (idx >= 0) {
                    ids.splice(idx, 1);
                    result.push(item);
                } else {
                    patch = true;
                }
            }

            if (!patch) {
                // check for bugged website menu items in the database, would be fixed by migration
                patch = (result.length !== items.length)
                    || result.some(({ page }, index) => items[index].page !== page);
            }

            return {
                enabled,
                patch,
                items: result,
            };
        },
        [website.pages, items, homePageId, enabled]
    );
};
