export const colorThemeFactory = ({ id, name, colors }) => ({
    id,
    name,
    theme: {
        colorId: id,
        colors,
        page: {
            backgroundColor: colors.background,
        },
        blocks: {
            // do not remove: block styling auto-generation (empty styling can be removed)
            textrich: {
                color: colors.text,
            },
            timer: {
                color: colors.text,
            },
            giphy: {},
            customcode: {
                color: colors.featureTextColor,
                backgroundColor: colors.featureBackgroundColor,
            },
            subscriptionform: {
                color: colors.text,
                buttonBackground: colors.buttonBackground,
                buttonTextColor: colors.buttonTextColor,
                inputBackgroundColor: colors.featureBackgroundColor,
                inputTextColor: colors.featureTextColor,
            },
            servicesgrid: {
                color: colors.text,
                backgroundColor: colors.featureBackgroundColor,
                buttonBackground: colors.buttonBackground,
                buttonTextColor: colors.buttonTextColor,
            },
            custom: {},
            products: {
                color: colors.text,
                productsCategoryTextColor: colors.productsCategoryTextColor,
                backgroundColor: colors.featureBackgroundColor,
                buttonBackground: colors.buttonBackground,
                buttonTextColor: colors.buttonTextColor,
            },
            hat: {
                textColor: colors.text,
                paneColor: colors.panel,
            },
            divider: {
                backgroundColor: colors.dividerColor,
            },
            heading: {
                color: colors.text,
            },
            text: {
                color: colors.text,
            },
            link: {
                backgroundColor: colors.buttonBackground,
                color: colors.buttonTextColor,
                contrastedColor: false,
            },
            messengers: {
                themeColors: !['lightTheme', 'darkTheme', 'wbTheme', 'bwTheme'].includes(id),
                backgroundColor: colors.buttonBackground,
                color: colors.buttonTextColor,
            },
            accordion: {
                color: colors.text,
            },
            features: {
                color: colors.featureTextColor,
                backgroundColor: colors.featureBackgroundColor,
            },
            quote: {
                color: colors.text,
                lineColor: colors.panel,
            },
            list: {
                color: colors.text,
            },
            socials: {
                color: colors.text,
            },
        },
    },
});
