export const TextRichBlockKind = 'TextRich';
export const TimerBlockKind = 'Timer';
export const GiphyBlockKind = 'Giphy';
export const CustomCodeBlockKind = 'CustomCode';
export const SubscriptionFormBlockKind = 'SubscriptionForm';
export const CustomBlockKind = 'Custom';
export const ServicesGridBlockKind = 'ServicesGrid';
export const ProductsBlockKind = 'Products';
export const SocialsBlockKind = 'Socials';
export const QuoteBlockKind = 'Quote';
export const ListBlockKind = 'List';
export const AudioBlockKind = 'Audio';
export const VideoGalleryBlockKind = 'VideoGallery';
export const FeaturesBlockKind = 'Features';
export const AccordionBlockKind = 'Accordion';
export const ImageGalleryBlockKind = 'ImageGallery';
export const DividerBlockKind = 'Divider';
export const HatBlockKind = 'Hat';
export const HeadingBlockKind = 'Heading';
export const ImageBlockKind = 'Image';
export const MessengersBlockKind = 'Messengers';
export const LinkBlockKind = 'Link';
export const TextBlockKind = 'Text';
export const VideoBlockKind = 'Video';

export const ALL_BLOCK_KINDS = [
    TextRichBlockKind,
    TimerBlockKind,
    GiphyBlockKind,
    CustomCodeBlockKind,
    SubscriptionFormBlockKind,
    CustomBlockKind,
    ServicesGridBlockKind,
    ProductsBlockKind,
    SocialsBlockKind,
    QuoteBlockKind,
    ListBlockKind,
    AudioBlockKind,
    VideoGalleryBlockKind,
    FeaturesBlockKind,
    AccordionBlockKind,
    ImageGalleryBlockKind,
    DividerBlockKind,
    HatBlockKind,
    HeadingBlockKind,
    ImageBlockKind,
    MessengersBlockKind,
    LinkBlockKind,
    TextBlockKind,
    VideoBlockKind,
];
