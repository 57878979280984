import React from 'react';
import PropTypes from 'prop-types';

import {
    socialNameForUrl,
    iconForSocialName,
    socialIconColor,
    GENERIC_ICON_NAMES,
    GENERIC_ICON_GROUPS,
} from '@mssgme/socials';

export function GenericIcon({ name, ...rest }) {
    const Component = iconForSocialName(name);

    return <Component {...rest} />;
}

GenericIcon.propTypes = {
    name: PropTypes.string.isRequired,
};

export {
    //
    GENERIC_ICON_NAMES,
    GENERIC_ICON_GROUPS,
    socialNameForUrl,
    socialIconColor,
};
