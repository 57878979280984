import { colorThemeFactory } from './colorTheme';

export const creamTheme = colorThemeFactory({
    id: 'creamTheme',
    name: 'Cream',
    colors: {
        background: '#f8e5e5ff',
        panel: '#f9f6f6ff',
        text: '#000000ff',
        dividerColor: '#ffffffff',
        buttonBackground: '#000000ff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#f9f6f6ff',
        featureTextColor: '#000000ff',
        productsCategoryTextColor: '#000000ff',
    },
});
