import format from 'string-template';

class Trans {
    constructor(locales = {}) {
        this.locales = locales;
    }

    extend(locales) {
        this.locales = Object.assign({}, this.locales, locales);
    }

    t(key, values) {
        if (!this.locales[key]) return key;
        return format(this.locales[key], values);
    }
}

export const trans = new Trans();
export const __t = trans.t.bind(trans);
