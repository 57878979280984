import { colorThemeFactory } from './colorTheme';

export const sweetTheme = colorThemeFactory({
    id: 'sweetTheme',
    name: 'Sweet',
    colors: {
        background: '#e1f2f7ff',
        panel: '#ef0d50ff',
        text: '#000000ff',
        dividerColor: '#ef0d50ff',
        buttonBackground: '#ef0d50ff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#d2e3e8ff',
        featureTextColor: '#000000ff',
        productsCategoryTextColor: '#000000ff',
    },
});
