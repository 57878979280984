import { useEffect } from 'react';

export const applyElementStyle = (node, style) => {
    if (!(node && style)) {
        return;
    }

    const prev = {};

    for (const key in style) {
        prev[key] = node.style[key];
    }

    Object.assign(node.style, style);

    return () => {
        Object.assign(node.style, prev);
    };
};

export const applyElementStyles = (...list) => {
    const restorers = list.map(([node, style]) => applyElementStyle(node, style));

    return () => {
        for (const restore of restorers) {
            if (restore) restore();
        }
    };
};

export const useElementStyle = (node, style) => useEffect(() => applyElementStyle(node, style), [node, style]);
