import { colorThemeFactory } from './colorTheme';

export const strongTheme = colorThemeFactory({
    id: 'strongTheme',
    name: 'Strong',
    colors: {
        background: '#191919ff',
        panel: '#fab162ff',
        text: '#ffffffff',
        dividerColor: '#fab162ff',
        buttonBackground: '#fab162ff',
        buttonTextColor: '#000000ff',
        featureBackgroundColor: '#333333ff',
        featureTextColor: '#ffffffff',
        productsCategoryTextColor: '#ffffffff',
    },
});
