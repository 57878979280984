// do not remove: block import auto-generation
import { default as TextRich } from './TextRich';
import { default as Divider } from './Divider';
import { default as Hat } from './Hat';
import { default as Heading } from './Heading';
import { default as Messengers } from './Messengers';
import { default as Link } from './Link';
import { default as Text } from './Text';
import { default as Quote } from './Quote';
import { default as List } from './List';
import { default as Image } from './Image';
import { default as Socials } from './Socials';
import { default as Features } from './Features';
import { default as Timer } from './Timer';
import { default as CustomCode } from './CustomCode';
import { default as Custom } from './Custom';
import { default as Accordion } from './Accordion';

import lazyBundle from './lazy';
import { BlockBase } from './BlockBase';
import {
    AccordionBlockKind,
    AudioBlockKind,
    CustomBlockKind,
    CustomCodeBlockKind,
    DividerBlockKind,
    FeaturesBlockKind,
    GiphyBlockKind,
    HatBlockKind,
    HeadingBlockKind,
    ImageBlockKind,
    ImageGalleryBlockKind,
    LinkBlockKind,
    ListBlockKind,
    MessengersBlockKind,
    ProductsBlockKind,
    QuoteBlockKind,
    ServicesGridBlockKind,
    SocialsBlockKind,
    SubscriptionFormBlockKind,
    TextBlockKind,
    TextRichBlockKind,
    TimerBlockKind,
    VideoBlockKind,
    VideoGalleryBlockKind,
} from '@mssgme/shared';

export * from './gridUtils';

const { PageProductsRouting, SubscriptionForm, Products, ServicesGrid } = lazyBundle(() => import('./shopping'));
const { Giphy, Audio, Video, VideoGallery, ImageGallery } = lazyBundle(() => import('./media'));

const BLOCKS_MAP = {
    // do not remove: block enum auto-generation
    // direct imports
    [TextRichBlockKind]: TextRich,
    [LinkBlockKind]: Link,
    [TextBlockKind]: Text,
    [QuoteBlockKind]: Quote,
    [ListBlockKind]: List,
    [DividerBlockKind]: Divider,
    [HatBlockKind]: Hat,
    [HeadingBlockKind]: Heading,
    [MessengersBlockKind]: Messengers,
    [ImageBlockKind]: Image,
    [SocialsBlockKind]: Socials,

    // bundled imports
    [SubscriptionFormBlockKind]: SubscriptionForm,
    [ProductsBlockKind]: Products,
    [ServicesGridBlockKind]: ServicesGrid,

    [AccordionBlockKind]: Accordion,
    [FeaturesBlockKind]: Features,
    [TimerBlockKind]: Timer,
    [CustomCodeBlockKind]: CustomCode,
    [CustomBlockKind]: Custom,

    [GiphyBlockKind]: Giphy,
    [AudioBlockKind]: Audio,
    [VideoBlockKind]: Video,
    [VideoGalleryBlockKind]: VideoGallery,
    [ImageGalleryBlockKind]: ImageGallery,
    // isolated lazy imports
};

export {
    BLOCKS_MAP,
    BlockBase,
    // do not remove: block export auto-generation
    TextRich,
    Link,
    Text,
    Quote,
    List,
    Divider,
    Hat,
    Heading,
    Messengers,
    SubscriptionForm,
    Products,
    ServicesGrid,
    Accordion,
    Features,
    Socials,
    Timer,
    CustomCode,
    Custom,
    Giphy,
    Audio,
    Image,
    Video,
    VideoGallery,
    ImageGallery,
    PageProductsRouting,
};
