function hasOwnProperty(obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

export default function queryString(obj) {
    let arr = [];

    for (let prop in obj) {
        if (hasOwnProperty(obj, prop)) {
            const fragment = [prop, obj[prop]].join('=');
            arr.push(fragment);
        }
    }

    return arr.length > 0 ? `?${arr.join('&')}` : '';
}

queryString.parse = function parse(str) {
    const pairs = (str || '').replace('?', '').split('&');
    const result = {};

    for (const pair of pairs) {
        const trimmed = pair.replace(/\+/g, '%20');
        const idx = trimmed.indexOf('=');

        if (idx <= 0) {
            continue;
        }

        const key = decodeURIComponent(trimmed.slice(0, idx));
        const value = decodeURIComponent(trimmed.slice(idx + 1));

        if (!hasOwnProperty(result, key)) {
            result[key] = value;
        } else if (Array.isArray(result[key])) {
            result[key].push(value);
        } else {
            result[key] = [result[key], value];
        }
    }

    return result;
};
