import uuid from 'uuid/v4';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useEffect } from 'react';
import { useCookie } from 'react-use';
import { str as crc32 } from 'crc-32';

import { queryString } from '@mssgme/shared';

const key = '_msab';
const expiration = 30;
const generateUid = () => `MSAB_${uuid()}`;

export const ExperimentsContext = React.createContext({ uid: '', experiments: [] });

const getVariant = (variants, uid) => {
    if (variants.length > 1) {
        const sum = variants.reduce((acc, { weight }) => acc + weight, 0);
        let cohort = Math.abs(crc32(uid)) % sum;

        for (const variant of variants) {
            cohort -= variant.weight;

            if (cohort < 0) {
                return variant;
            }
        }
    }

    return variants[0];
};

const readVariant = (experiment) => {
    return queryString.parse(window.location.search)[`_ab_${experiment}`];
};

export function useExperiment({ id, experiment: raw }) {
    const { uid, experiments } = useContext(ExperimentsContext);

    return useMemo(() => {
        const override = readVariant(raw?.id || id);
        const experiment = raw || (id && experiments.find((exp) => exp.id === id));

        if (!experiment) {
            return override;
        }

        if (override && experiment.variants.find(({ id }) => id === override)) {
            return override;
        }

        const variant = getVariant(experiment.variants, uid);

        return variant?.id;
    }, [uid, experiments]);
}

export const ExperimentsProvider = ({ config, children }) => {
    const [uidCookie, setUid] = useCookie(key);
    const value = useMemo(() => {
        const uid = uidCookie || generateUid();

        return {
            ...config,
            uid,
        };
    }, []);

    useEffect(() => {
        if (uidCookie !== value.uid) {
            setUid(value.uid, { expires: expiration });
        }
    }, [uidCookie, value.uid]);

    return <ExperimentsContext.Provider value={value}>{children}</ExperimentsContext.Provider>;
};

ExperimentsProvider.propTypes = {
    config: PropTypes.shape({
        uid: PropTypes.string,
        experiments: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                variants: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string.isRequired,
                        weight: PropTypes.number.isRequired,
                    }).isRequired
                ).isRequired,
            }).isRequired
        ).isRequired,
    }).isRequired,
};
