import socialIcons from './db-socials';
import generalIcons from './db-general';

const unique = (db) => [...new Set(Object.entries(db).map(([key, { alias }]) => alias || key))];
const DB = {
    ...generalIcons,
    ...socialIcons,
};
export const GENERIC_ICON_GROUPS = {
    general: unique(generalIcons),
    social: unique(socialIcons),
};
export const DEFAULT_KEY = 'sharethis';
export const KEYS = Object.keys(DB);
export const GENERIC_ICON_NAMES = unique(DB);
const KEYS_REGEX = new RegExp('^(?:https?:\\/\\/(?:[a-z0-9-]*\\.)?)?(' + KEYS.join('|') + ')\\..*');

export function iconForSocialName(key) {
    return DB[key] ? DB[key].icon : null;
}

export function socialIconColor(key) {
    return DB[key] ? DB[key].color : null;
}

export function socialNameForUrl(url, def = DEFAULT_KEY) {
    if (!url) {
        return def;
    }

    let key = url.replace(KEYS_REGEX, '$1');
    let found = DB[key];

    if (key === url || !found) {
        return def;
    }

    if (found?.subnetworks) {
        for (const subnetwork of found.subnetworks) {
            if (url.includes(`${subnetwork}.${key}`)) {
                key = subnetwork;
                found = DB[key];
            }
        }
    }


    return found.alias || key;
}

export function socialNamesForUrls(urls) {
    if (!urls || !Array.isArray(urls) || urls.length === 0) {
        return [];
    }

    return urls.map(socialNameForUrl);
}
