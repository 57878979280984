import queryString from '../../helpers/queryString';
import Link from './link';

const SUPPORT = ['text'];

export default class WhatsAppLink extends Link {
    isSupported(param) {
        if (SUPPORT.indexOf(param) >= 0) return true;
        return false;
    }

    getUrl() {
        if (this._auto || this._bot) {
            return this._name;
        } else {
            const number = this._name.replace('+', '');
            return `https://wa.me/${number + queryString(this._params)}`;
        }
    }

    getPlatform() {
        return 'whatsapp';
    }
}
