import { combineReducers } from 'redux';
// import orderReducer from './orderReducer';
import ordersReducer from './ordersReducer';

const rootReducer = combineReducers({
    // order: orderReducer,
    orders: ordersReducer,
    page: (state = null) => state,
    mode: (state = null) => state,
    website: (state = {}) => state,
});

export default rootReducer;
