import queryString from '../../helpers/queryString';
import Link from './link';

const BOT_SUPPORT = ['start'];

export default class TelegramLink extends Link {
    isSupported(param) {
        return !!(this._bot && BOT_SUPPORT.indexOf(param) >= 0);
    }

    getUrl() {
        if (this._auto) {
            return this._name;
        } else if (this._secret) {
            return `tg://join?invite=${this._name}`;
        } else if (/^https?:\/\/t\.me\/(\+|joinchat\/).+$/i.test(this._name)) {
            // TODO: temporary solution, need to refactor
            // TODID: refactored, but needs further refactoring
            return this._name.replace(/(^https?:\/\/t\.me\/(?:\+|joinchat\/))(.+)/, 'tg://join?invite=$2');
        } else {
            return `tg://resolve${queryString(Object.assign({ domain: this._name }, this._params))}`;
        }
    }

    getPlatform() {
        return 'telegram';
    }
}
