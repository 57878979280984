import React from 'react';

import { CRM_IDENTITY } from '../products';

export const WebsiteDataContext = React.createContext({
    _id: '',
    workspace: '',
    name: '',
    slug: '',
    absoluteUrl: '',
    finalUrl: '',
    homePageId: '',
    collections: [],
    pages: [],
    domain: {},
    seo: {},
    localization: {},
    crm: CRM_IDENTITY,
});
