import TinyColor from 'tinycolor2';
import { get, pickBy, set } from 'lodash';

import { isLightColor } from '@mssgme/helpers';
import { mergeTheme } from '@mssgme/shared';
import { diffTheme } from './diff';

// todo: use Yup validation here?
const validateValue = (value, field) => {
    if (value === undefined) {
        return field.initial;
    }

    switch (field.type) {
        case 'select':
        case 'choice':
            if (field.multiple) {
                if (
                    Array.isArray(value) &&
                    value.every((item) => field.options.some((option) => option.value === item))
                ) {
                    return value;
                }
            } else if (field.options.some((option) => option.value === value)) {
                return value;
            }

            break;

        default:
            return value;
    }

    return field.initial;
};

const cleanObj = (obj) => pickBy(obj, (v) => v !== undefined);

const directTransforms = ['color', 'fontSize', 'textAlign', 'borderColor', 'backgroundColor'];
const gradients = {
    falloff: (color) => {
        const c1 = color;
        const c2 = color.clone().darken(30);

        return `linear-gradient(to bottom, ${c1}, ${c2})`;
    },
    slope: (color) => {
        const isLight = isLightColor(color);
        const c2 = color.clone().darken(isLight ? 5 : 20);

        return `linear-gradient(180deg, ${color}, ${c2})`;
    },
};
const units = (attribute, def = '%') => (style, value, theme) => {
    if (typeof value !== 'number') {
        return;
    }

    const units = get(theme.units, attribute, def); // assume percent by default
    style[attribute] = `${value}${units}`;
};

const transformMap = {
    width: units('width'),
    height: units('height'),
    minHeight: units('minHeight', 'px'),
    lineHeight: units('lineHeight', 'px'),
    padding: units('padding', 'px'),
    borderRadius: units('borderRadius'),
    gradient: (style, value, theme) => {
        if (!value) {
            return;
        }

        const gradient = value === true ? gradients.slope : gradients[value];

        if (!gradient) {
            throw new Error(`Gradient "${value}" is not defined`);
        }

        const color = new TinyColor(theme.backgroundColor);

        style.backgroundImage = gradient(color);
    },
    contrastedColor: (style, value, theme) => {
        if (!value) {
            return;
        }

        const isLight = isLightColor(theme.backgroundColor);
        const contrasted = isLight ? '#333' : '#fff';
        style.color = String(contrasted);
    },
    textStyle: (style, decorations) =>
        decorations.forEach((decoration) => {
            switch (decoration) {
                case 'bold':
                    style.fontWeight = decoration;
                    break;
                case 'italic':
                    style.fontStyle = decoration;
                    break;
                case 'underline':
                case 'line-through':
                    if (style.textDecorationLine) {
                        style.textDecorationLine += ' ' + decoration;
                    } else {
                        style.textDecorationLine = decoration;
                    }
                    break;
            }
        }),
    font: (style, value) => {
        style.fontFamily = value.family;
    },
};

export const Theme = {
    resolve(path, base, child) {
        if (base === child || !child) {
            return path ? get(base, path, {}) : base;
        }

        if (path) {
            base = get(base, path, {});
            child = get(child, path, {});
        }

        return mergeTheme(base, child);
    },

    compile(theme, result = {}) {
        Object.entries(theme).forEach(([attribute, value]) => {
            if (value === undefined || value === null) {
                return;
            }

            if (typeof value === 'object' && !Array.isArray(value) && attribute !== 'font') {
                result[attribute] = this.compile(value);
            } else if (directTransforms.includes(attribute)) {
                result[attribute] = value;
            } else {
                const transformer = transformMap[attribute];

                if (transformer) {
                    transformer(result, value, theme);
                }
            }
        });

        return result;
    },

    enrich(base, values, { parent, path, sections }, setDisabled) {
        const resolved = this.resolve(path, parent ? get(base, parent) : base, values);
        const result = {};
        const getter = (prop) => get(resolved, prop);

        for (const { styles } of sections) {
            for (const field of styles) {
                if (field.if) {
                    continue;
                }

                const stylePath = field.path;
                const stored = get(resolved, stylePath);
                const validated = validateValue(stored, field);

                set(result, stylePath, validated);
                set(resolved, stylePath, validated);
            }

            for (const field of styles) {
                if (!field.if) {
                    continue;
                }

                const stylePath = field.path;
                let validated = undefined;

                if (field.if(getter)) {
                    validated = validateValue(get(resolved, stylePath), field);
                } else if (setDisabled) {
                    validated = field.disabledValue;
                }

                set(result, stylePath, validated);
                set(resolved, stylePath, validated);
            }
        }

        return set({}, path, cleanObj(result));
    },

    diff(base, values, definition) {
        return diffTheme(base, this.enrich(base, values, definition, true));
    },
};
