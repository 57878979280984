import { useState, useEffect } from 'react';
import NotificationCenter from './notification-center';

function useNotificationCenter() {
    const [, setCount] = useState(0);
    const notificationCenter = NotificationCenter.getInstance();

    useEffect(() => {
        const handleEvent = () => setCount((prevCount) => prevCount + 1);
        notificationCenter.on('notify', handleEvent);
        notificationCenter.on('remove', handleEvent);
        notificationCenter.on('clear', handleEvent);

        return () => {
            notificationCenter.off('notify', handleEvent);
            notificationCenter.off('remove', handleEvent);
            notificationCenter.off('clear', handleEvent);
        };
    }, []);

    return notificationCenter;
}

export default useNotificationCenter;
