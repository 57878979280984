import Link from './link';

export default class WhatsAppLink extends Link {
    getUrl() {
        return `skype:${this._name}?chat`;
    }

    getPlatform() {
        return 'skype';
    }
}
