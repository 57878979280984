import { useCallback, useRef, useState } from 'react';
import { useEventCallback } from '@mssgme/hooks';

export const useScrollTo = (delay = 300) => {
    const contentRef = useRef(null);

    const scrollTo = useCallback((to, behavior = 'smooth', delayOverride) => {
        const timer = setTimeout(() => {
            const container = contentRef.current;

            if (!container) {
                return;
            }

            let top = null;
            let hashEl;

            switch (to) {
                case 'top':
                    top = -container.scrollTop;
                    break;
                case 'bottom':
                    top = container.scrollHeight - container.clientHeight;
                    break;
                default:
                    hashEl = container.querySelector(to);
            }

            if (hashEl) {
                hashEl.scrollIntoView({ behavior, block: 'start' });
            } else if (Number.isFinite(top)) {
                container.scroll({ top, behavior });
            }
        }, delayOverride || delay);

        return () => {
            clearTimeout(timer);
        };
    });

    return [contentRef, scrollTo];
};
