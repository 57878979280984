/*global Sentry*/
import isAbsoluteUrl from 'is-absolute-url';
import contentType from 'content-type';
import { set } from 'lodash';

import { ValidationError } from './errors';

export const JSON_CONTENT_TYPE = 'application/json';

export function isFormData(form) {
    return form instanceof FormData;
}

export function isObject(obj) {
    return typeof obj === 'object';
}

export function isJsonContentType(headers) {
    const ct = headers.get('Content-Type');

    if (!ct) {
        return false;
    }

    return contentType.parse(ct).type === JSON_CONTENT_TYPE;
}

export function normalizeUrl(url) {
    return isAbsoluteUrl(url) ? url : window.location.origin + url;
}

export const asyncify = (fn) => async (...args) => fn(...args);

export function parseErrors(json) {
    const errors = {};

    if (json?.errors) {
        for (let name in json.errors) {
            set(errors, name, json.errors[name]);
        }
    }

    return errors;
}

function logError(error, extras) {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(error, extras);
    } else if ('Sentry' in window) {
        Sentry.withScope((scope) => {
            scope.setExtras(extras);

            Sentry.captureException(error);
        });
    }
}

export function createActionCreator(type) {
    type = `@API/${type}`;

    function actionCreator(data) {
        return { type, ...data };
    }

    actionCreator.type = type;
    actionCreator.toString = () => type;

    return actionCreator;
}

export function createSocketListener(socketFactory, eventName, transformPayload) {
    const type = `@API/SOCKET/${eventName}`;

    if (!transformPayload) {
        transformPayload = (payload) => ({ payload });
    }

    const actionCreator = () => (dispatch) => {
        const socket = socketFactory.createSocket();

        socket.on(eventName, (payload, meta = {}) => {
            const ignore = meta.clientId === socketFactory.clientId;

            if (ignore) {
                return;
            }

            dispatch({
                type,
                socketId: socket.id,
                ...meta,
                ...transformPayload(...(Array.isArray(payload) ? payload : [payload])),
                ignore: false,
            });
        });
    };
    actionCreator.type = type;
    actionCreator.toString = () => type;

    return actionCreator;
}

export function createActionCreators(api, target = {}) {
    function createThunkActionCreator(name, apiCall) {
        apiCall = asyncify(apiCall);

        const pending = createActionCreator(name);
        const fulfilled = createActionCreator(`${name}/SUCCESS`);
        const rejected = createActionCreator(`${name}/FAILURE`);

        function thunkActionCreator(arg0 = {}) {
            arg0 = { ...api._params, ...arg0 };

            return async (dispatch, getState) => {
                dispatch(pending(arg0));

                try {
                    const result = await apiCall(arg0);
                    dispatch(fulfilled({ ...arg0, result }));

                    return result;
                } catch (error) {
                    dispatch(rejected({ ...arg0, error }));

                    if (!ValidationError.is(error)) {
                        logError(error, {
                            action: rejected.toString(),
                            state: getState(),
                        });
                    }

                    throw error;
                }
            };
        }

        thunkActionCreator.request = pending;
        thunkActionCreator.requestType = pending.type;
        thunkActionCreator.success = fulfilled;
        thunkActionCreator.successType = fulfilled.type;
        thunkActionCreator.failure = rejected;
        thunkActionCreator.failureType = rejected.type;
        thunkActionCreator.toString = pending.toString;
        thunkActionCreator.type = pending.type;

        return thunkActionCreator;
    }

    function deepPenetration(obj, parentPath, result = {}) {
        for (const [prop, value] of Object.entries(obj)) {
            if (prop.startsWith('_')) {
                continue;
            }

            const path = parentPath ? `${parentPath}.${prop}` : prop;

            if (typeof value === 'object') {
                result[prop] = deepPenetration(value, path);
            } else if (typeof value === 'function') {
                result[prop] = createThunkActionCreator(path, value);
            }
        }

        return result;
    }

    return deepPenetration(api.resources, null, target);
}
