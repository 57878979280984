import { colorThemeFactory } from './colorTheme';

export const taxiTheme = colorThemeFactory({
    id: 'taxiTheme',
    name: 'Taxi',
    colors: {
        background: '#fdd935ff',
        panel: '#000000ff',
        text: '#000000ff',
        dividerColor: '#000000ff',
        buttonBackground: '#000000ff',
        buttonTextColor: '#fdd935ff',
        featureBackgroundColor: '#e3c22fff',
        featureTextColor: '#000000ff',
        productsCategoryTextColor: '#000000ff',
    },
});
