import { memoize } from 'lodash';

import { mergeTheme } from './merge';
import { styleThemeFactory } from './styleTheme';
import { getColorThemeById } from './colors';
import { computeDiff } from '../helpers';

export * from './styleTheme';
export * from './colors';

export { mergeTheme };

export const Themes = {
    simple: styleThemeFactory({
        id: 'simple',
        name: 'Simple',
    }),
};

export const getThemeById = memoize((id) => mergeTheme(Themes.simple.theme, getColorThemeById(id)));
export const getDefaultTheme = () => getThemeById();

export const getThemeOverride = (base, overrideId) => {
    const baseTheme = getThemeById(base.colorId);
    const overrideTheme = getThemeById(overrideId);
    const themeDiff = computeDiff(baseTheme, base);

    return mergeTheme(overrideTheme, themeDiff);
};
