import PropTypes from 'prop-types';
import React from 'react';

import { LinkBlockKind } from '@mssgme/shared';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { SmartButton } from '../../../UI';
import { BlockBase, useBlockHorizontalInsetsPadding } from '../BlockBase';

export default function Link({ block, onClick, insets, ...rest }) {
    const { theme, style } = useBlockTheme(block);
    const blockInsets = useBlockHorizontalInsetsPadding(insets, theme.padding);

    return (
        <BlockBase insets={blockInsets} {...rest}>
            <SmartButton
                width={theme.width}
                icon={theme.icon}
                urlSubtitle={theme.urlSubtitle}
                id={block._id}
                link={block}
                style={style}
                interactive={rest.interactive}
                isViewMode={rest.isViewMode}
                isWebsitePublished={rest.isWebsitePublished}
                onClick={onClick}
            />
        </BlockBase>
    );
}

Link.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
    onClick: PropTypes.func,
};

Link.defaultProps = BlockBase.defaultProps;

Link.kind = LinkBlockKind;
