import { colorThemeFactory } from './colorTheme';

export const tealTheme = colorThemeFactory({
    id: 'tealTheme',
    name: 'Teal',
    colors: {
        background: '#3aafa9ff',
        panel: '#4bbfbaff',
        text: '#ffffffff',
        dividerColor: '#ffffff4d',
        buttonBackground: '#19242aff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#4bbfbaff',
        featureTextColor: '#ffffffff',
        productsCategoryTextColor: '#ffffffff',
    },
});
