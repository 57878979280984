import config from 'config';

export const MediaSizes = {
    Original: 'original',
    Large: 'medium',
    Half: 'small',
    Icon: 'icon',
};

function resolve(from, to) {
    const resolvedUrl = new URL(to, new URL(from, 'resolve://'));

    if (resolvedUrl.protocol === 'resolve:') {
        // `from` is a relative URL.
        const { pathname, search, hash } = resolvedUrl;

        return pathname + search + hash;
    }

    return resolvedUrl.toString();
}

export function resolveUploadSize(uploads, url, size = MediaSizes.Original, force = false) {
    if (!url) {
        return url;
    }

    const isAbsoluteUrl = isAbsolute(url);
    const isUploadUrl = !(isAbsoluteUrl && !isLocal(url));

    if (isUploadUrl && size !== MediaSizes.Original) {
        const relativeUrl = isAbsoluteUrl ? getRelativeUrl(url) : url;
        const upload = uploads.find(({ path }) => path === relativeUrl);

        if (upload) {
            const found = upload.metadata.sizes.find(({ type }) => type === size);

            if (found) {
                return found.url;
            }
        } else if (force) {
            url = addFilenameSuffix(url, `-${size}`);
        }
    }

    return isAbsoluteUrl ? url : getAbsoluteUrl(url);
}

export function resolveUpload(uploads, url, size = MediaSizes.Original) {
    if (!url) {
        return {
            size: null,
            upload: null,
        };
    }

    const isAbsoluteUrl = isAbsolute(url);
    const isUploadUrl = !(isAbsoluteUrl && !isLocal(url));

    if (isUploadUrl) {
        const relativeUrl = isAbsoluteUrl ? getRelativeUrl(url) : url;
        const upload = uploads.find(({ path }) => path === relativeUrl);

        if (upload) {
            const found = upload.metadata.sizes.find(({ type }) => type === size);

            if (found) {
                return {
                    size: found,
                    upload,
                };
            } else {
                return {
                    size: upload,
                    upload,
                };
            }
        }
    }

    return {
        size: null,
        upload: null,
    };
}

export function normalizeUrls(val) {
    if (!val) return [];
    const arr = Array.isArray(val) ? val : [val];
    return arr.filter((image) => image);
}

export function isLocal(mediaUrl) {
    return mediaUrl.startsWith(config.media_url);
}

export function isAbsolute(mediaUrl) {
    return /^[a-z][a-z\d+.-]*:/.test(mediaUrl);
}

export function getAbsoluteUrl(mediaUrl) {
    if (!mediaUrl) return null;
    return isAbsolute(mediaUrl) ? mediaUrl : resolve(config.media_url, mediaUrl);
}

export function getRelativeUrl(mediaUrl) {
    if (!mediaUrl) return null;
    return mediaUrl.startsWith(config.media_url) ? mediaUrl.slice(config.media_url.length + 1) : mediaUrl;
}

export function computeChanges(oldUrls, newUrls) {
    oldUrls = normalizeUrls(oldUrls);
    newUrls = normalizeUrls(newUrls);

    const deleted = oldUrls.filter((url) => !newUrls.includes(url));
    const added = newUrls.filter((url) => !oldUrls.includes(url));

    return {
        remove: deleted,
        keep: added,
    };
}

export function addFilenameSuffix(path, suffix) {
    const match = path.match(/^(.*)\.([^.]+)$/);

    if (match) {
        return `${match[1]}${suffix}.${match[2]}`;
    }

    return path;
}

export function generateAbsoluteUrl(path) {
    if (!path) return path;

    const urlObj = new URL(path, config.media_url);
    return urlObj.href;
}

export function generateThumbnailUrl(path, variant) {
    if (!path) return path;

    const urlObj = new URL(path, config.media_url);
    urlObj.pathname = `/thumbnails/${variant + urlObj.pathname}`;
    return urlObj.href;
}

export function generateProductThumbnailUrl(path) {
    return generateThumbnailUrl(path, 'product_cover');
}
