import { createActionCreators, createActionCreator } from '@mssgme/api-client';

import { FrontApiClient } from '../../lib';

export const frontApiClient = new FrontApiClient({
    baseUrl: process.env.REACT_APP_STOREFRONT_API_URL || '/storefront/api',
});
export const clearOrder = createActionCreator('CLEAR_ORDER');
export const storefrontApiActions = createActionCreators(frontApiClient, {
    clearOrder,
});
