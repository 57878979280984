import { useElementTheme, useTheme } from '@mssgme/ui';

export function usePageTheme(inherit = true, compile = false) {
    return useElementTheme({
        baseTheme: useTheme(),
        elementPath: 'page',
        compile,
        inherit,
    });
}

export function useBlockTheme(block, compile = true) {
    return useElementTheme({
        theme: block.theme,
        baseTheme: useTheme(),
        elementPath: `blocks.${block.kind.toLowerCase()}`,
        compile,
    });
}
