import { colorThemeFactory } from './colorTheme';

export const acidTheme = colorThemeFactory({
    id: 'acidTheme',
    name: 'Acid',
    colors: {
        background: '#c8eb00ff',
        panel: '#d9fc11ff',
        text: '#000000ff',
        dividerColor: '#ffffffff',
        buttonBackground: '#000000ff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#d9fc11ff',
        featureTextColor: '#000000ff',
        productsCategoryTextColor: '#000000ff',
    },
});
