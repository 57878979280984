export default function resolvePathname(...args) {
    const path = args.join('/');
    const leadSlash = path.charAt(0) === '/' ? '/' : '';
    const parts = path.split('/');
    const res = [];

    for (let i = 0; i < parts.length; i++) {
        if (typeof parts[i] !== 'string' || !parts[i] || parts[i] === '.') {
            continue;
        } else if (parts[i] === '..') {
            res.pop();
        } else {
            res.push(parts[i]);
        }
    }

    return leadSlash + res.join('/');
}
