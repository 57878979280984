import { EventEmitter } from 'events';
import { NOTIFICATIONS_DELAY } from '@mssgme/constants';

export const NOTIFY_STYLES = {
    success: 'success',
    danger: 'danger',
};

const defaultOptions = {
    closeDelay: 0,
    notifyStyle: NOTIFY_STYLES.success,
    showClose: false,
    isRaw: false,
};

class NotificationCenter extends EventEmitter {
    constructor() {
        super();
        this.count = 0;
        this.notifications = new Map();
    }

    /**
     * @private
     */
    static instance;

    /**
     * Show popup-style notification with given style and behavior.
     *
     * @param {string} message Message to display
     * @param {object} [options] Optional notification options
     * @param {number} [options.closeDelay=0] Close notification automatically after this delay. Automatically set
     *  to `2000` ms if `showClose` is set to `true`
     * @param {NOTIFY_STYLES.danger|NOTIFY_STYLES.success} [options.notifyStyle=NOTIFY_STYLES.success] Notification style
     * @param {boolean} [options.showClose=false] Show "`Close`" button
     * @param {boolean} [options.isRaw=false] Is message in `HTML` format
     * @returns {number} ID of created notification
     */
    notify(message, options) {
        const notification = this.createNotification(message, {
            closeDelay: options.showClose ? NOTIFICATIONS_DELAY : 0,
            ...options,
        });
        this.handleNotificationDelay(notification);
        this.notifications.set(notification.id, notification);
        this.emit('notify', notification);

        return notification.id;
    }

    /**
     * Show popup-style `success` notification with given behavior.
     *
     * @param {string} message Message to display
     * @param {object} [options] Optional notification options
     * @param {number} [options.closeDelay=0] Close notification automatically after this delay. Automatically set
     *  to `2000` ms if `showClose` is set to `true`
     * @param {boolean} [options.showClose=false] Show "`Close`" button
     * @param {boolean} [options.isRaw=false] Is message in `HTML` format
     * @returns {number} ID of created notification
     */
    success(message, options) {
        return this.notify(message, { notifyStyle: NOTIFY_STYLES.success, ...options });
    }

    /**
     * Show popup-style `danger` notification with given behavior.
     *
     * @param {string} message Message to display
     * @param {object} [options] Optional notification options
     * @param {number} [options.closeDelay=0] Close notification automatically after this delay. Automatically set
     *  to `2000` ms if `showClose` is set to `true`
     * @param {boolean} [options.showClose=false] Show "`Close`" button
     * @param {boolean} [options.isRaw=false] Is message in `HTML` format
     * @returns {number} ID of created notification
     */
    danger(message, options) {
        return this.notify(message, { notifyStyle: NOTIFY_STYLES.danger, ...options });
    }

    /**
     * Create message object.
     *
     * @param {string} message Message content
     * @param {object} [options] Optional notification options
     * @param {number} [options.closeDelay=0] Close notification automatically after this delay. Automatically set
     *  to `2000` ms if `showClose` is set to `true`
     * @param {NOTIFY_STYLES.danger|NOTIFY_STYLES.success} [options.notifyStyle=NOTIFY_STYLES.success] Notification style
     * @param {boolean} [options.showClose=false] Show "`Close`" button
     * @param {boolean} [options.isRaw=false] Is message in `HTML` format
     * @returns {{ id: number; message: string; options: object }} Message object
     * @private
     */
    createNotification(message, options) {
        return {
            id: this.getNotificationId(),
            message,
            options: { ...defaultOptions, ...options },
        };
    }

    /**
     * @private
     * @param {object} notification Notification object
     */
    handleNotificationDelay(notification) {
        const delay = notification.options.closeDelay;

        if (delay > 0) {
            notification.timerId = setTimeout(() => this.remove(notification.id), delay);
        }
    }

    /**
     * Remove notification with specified ID
     *
     * @param {number} id Notification ID
     */
    remove(id) {
        const notification = this.notifications.get(id);

        if (!notification) {
            return;
        }

        if (notification.timerId) {
            clearTimeout(notification.timerId);
        }

        this.notifications.delete(id);
        this.emit('remove', notification);
    }

    /**
     * Clear all notifications
     */
    clear() {
        this.notifications.clear();
        this.emit('clear');
    }

    /**
     * @private
     * @returns {number} New ID
     */
    getNotificationId() {
        return this.count++;
    }

    /**
     * Get an array of all committed notifications. Can be safely mutated.
     *
     * @returns {{ id: number; message: string; options: object }[]} Notification objects array
     */
    getNotifications() {
        return Array.from(this.notifications.values());
    }

    /**
     * Get an instance of notifications manager.
     *
     * @returns {NotificationCenter} Singletone instance
     */
    static getInstance() {
        if (!NotificationCenter.instance) {
            NotificationCenter.instance = new NotificationCenter();
        }

        return NotificationCenter.instance;
    }
}

export default NotificationCenter;
