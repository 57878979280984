const isMergeable = (value) => !!value && typeof value === 'object';
const clone = (value) => mergeTheme(Array.isArray(value) ? [] : {}, value);

const cloneArray = (source) => source.map((item) => (isMergeable(item) ? clone(item) : item));

const mergeObject = (target, source) => {
    const destination = {};

    if (isMergeable(target)) {
        Object.keys(target).forEach((key) => {
            const value = target[key];

            destination[key] = isMergeable(value) ? clone(value) : value;
        });
    }

    source &&
        Object.keys(source).forEach((key) => {
            const value = source[key];
            const mergeable = isMergeable(value);

            if (!mergeable || !target[key]) {
                destination[key] = mergeable ? clone(value) : value;
            } else {
                destination[key] = mergeTheme(target[key], value);
            }
        });

    return destination;
};

export const mergeTheme = (target, source) => {
    const sourceIsArray = Array.isArray(source);
    const targetIsArray = Array.isArray(target);

    if (sourceIsArray !== targetIsArray && source && target) {
        return isMergeable(source) ? clone(source) : source;
    } else if (sourceIsArray) {
        return cloneArray(source);
    } else {
        return mergeObject(target, source);
    }
};
