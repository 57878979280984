import React from 'react';
import PropTypes from 'prop-types';

import { GenericIcon, ImagePreview } from '@mssgme/ui';
import { useUploads, MediaSizes } from '../../hooks';

export function Glyph({ glyph, size, ...rest }) {
    const { getMediaSizeUrl } = useUploads();

    if (glyph.type === 'icon' && glyph.icon) {
        return <GenericIcon name={glyph.icon} size={size} {...rest} />;
    }

    if (glyph.type === 'emoticon' && glyph.text) {
        return (
            <div
                {...rest}
                role="img"
                style={{
                    textAlign: 'center',
                    width: size,
                    height: size,
                    fontSize: size,
                    lineHeight: `${size}px`,
                    ...rest.style,
                }}
            >
                {glyph.text}
            </div>
        );
    }

    return (
        <ImagePreview
            {...rest}
            url={getMediaSizeUrl(glyph.url, MediaSizes.Icon)}
            style={{
                width: size,
                height: size,
                ...rest.style,
            }}
        />
    );
}

Glyph.propTypes = {
    size: PropTypes.number,
    glyph: PropTypes.shape({
        type: PropTypes.oneOf(['image', 'icon', 'emoticon']).isRequired,
        icon: PropTypes.string,
        url: PropTypes.string,
        absoluteUrl: PropTypes.string,
        text: PropTypes.string,
    }),
};

Glyph.defaultProps = {
    size: 16,
    glyph: { type: 'image' },
};
