import { colorThemeFactory } from './colorTheme';

export const darkTheme = colorThemeFactory({
    id: 'darkTheme',
    name: 'Classic Dark',
    colors: {
        background: '#333333ff',
        panel: '#444444ff',
        text: '#ffffffff',
        dividerColor: '#555555ff',
        buttonBackground: '#ffffffff',
        buttonTextColor: '#333333ff',
        featureBackgroundColor: '#444444ff',
        featureTextColor: '#ffffffff',
        productsCategoryTextColor: '#ffffffff',
    },
});
