import { useMemo } from 'react';

import { isMatchedById } from '@mssgme/shared';

export const useHomePageData = (website) => {
    return useMemo(() => (
        website.homePageId
            ? website.pages.find(isMatchedById(website.homePageId))
            : website.pages[0]
    ), [website]);
};
