import { colorThemeFactory } from './colorTheme';

export const bwTheme = colorThemeFactory({
    id: 'bwTheme',
    name: 'Black on White',
    colors: {
        background: '#ffffffff',
        panel: '#aaaaaaff',
        text: '#000000ff',
        dividerColor: '#555555ff',
        buttonBackground: '#000000ff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#f7f7faff',
        featureTextColor: '#333333ff',
        productsCategoryTextColor: '#000000ff',
    },
});
