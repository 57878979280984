import queryString from '../../helpers/queryString';
import Link from './link';

const BOT_SUPPORT = ['context', 'text'];

export default class ViberLink extends Link {
    isSupported(param) {
        if (this._bot && BOT_SUPPORT.indexOf(param) >= 0) return true;
        return false;
    }

    getUrl() {
        if (this._bot) {
            return `viber://pa${queryString(Object.assign({ chatURI: this._name }, this._params))}`;
        } else if (this._community) {
            return this._name;
        } else {
            const number = this._ua.Android ? this._name.replace('+', '') : this._name;
            return `viber://chat${queryString(Object.assign({ number: number }, this._params))}`;
        }
    }

    getPlatform() {
        return 'viber';
    }
}
