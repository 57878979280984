import { colorThemeFactory } from './colorTheme';

export const neonTheme = colorThemeFactory({
    id: 'neonTheme',
    name: 'Neon',
    colors: {
        background: '#2d283eff',
        panel: '#812bb2ff',
        text: '#ffffffff',
        dividerColor: '#812bb2ff',
        buttonBackground: '#812bb2ff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#423d51ff',
        featureTextColor: '#ffffffff',
        productsCategoryTextColor: '#ffffffff',
    },
});
