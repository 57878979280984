import queryString from '../../helpers/queryString';
import Link from './link';

const BOT_SUPPORT = ['ref', 'ref_source'];

export default class VKLink extends Link {
    isSupported(param) {
        if (this._bot && BOT_SUPPORT.indexOf(param) >= 0) return true;
        return false;
    }

    getUrl() {
        if (this._auto) {
            return this._name;
        } else {
            return `https://vk.me/${this._name + queryString(this._params)}`;
        }
    }

    getPlatform() {
        return 'vk';
    }
}
