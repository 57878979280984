import { useMemo } from 'react';
import { uaParser } from '@mssgme/shared';
import { useLanguage } from './useLanguage';
import { branding_url, main_hostname, fallback_language } from 'config';

export function useBrandingUrl({ target, url } = {}) {
    const lang = useLanguage();
    const { isMobile } = useMemo(() => uaParser(window.navigator?.userAgent), []);
    const resultUrl = useMemo(() => {
        const baseUrl = (url || branding_url).replace('{lang}', lang === fallback_language ? '' : `/${lang}`);
        const target = isMobile ? 'mobile' : 'desktop';
        const content =
            window.location.hostname === main_hostname
                ? window.location.pathname.split('/')[1]
                : window.location.hostname;
        const urlObj = new URL(baseUrl);
        const finalTarget = target || (isMobile ? 'mobile' : 'desktop');

        urlObj.search = new URLSearchParams({
            utm_source: 'userpage_branding_logo',
            utm_medium: `userpage_branding_${finalTarget}`,
            utm_campaign: `userpage_branding_${finalTarget}`,
            utm_content: content,
        });

        return urlObj.toString();
    }, [lang, target, url]);

    return resultUrl;
}
