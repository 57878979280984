import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { isMatchedById } from '@mssgme/shared';
import { LanguageContext } from '@mssgme/hooks';
import { ExperimentsProvider } from '@mssgme/ui';
import { PageDataContext, WebsiteDataContext } from '../../contexts';
import { PageContainer } from './PageContainer';

import './Website.scss';

const ab = {
    experiments: [
        {
            id: 'branding',
            variants: [
                { id: 'stickyDynamic', weight: 100 },
            ],
        },
    ],
};

export function Website({ basename, data: { website, page }, lang = 'en' }) {
    const currentPage = website.pages.find(isMatchedById(page));

    return (
        <Router basename={basename}>
            <ExperimentsProvider config={ab}>
                <LanguageContext.Provider value={lang}>
                    <WebsiteDataContext.Provider value={website}>
                        <PageDataContext.Provider value={currentPage}>
                            <Route path={['/:any(p|c)', '/:pageSlug?']} component={PageContainer} />
                        </PageDataContext.Provider>
                    </WebsiteDataContext.Provider>
                </LanguageContext.Provider>
            </ExperimentsProvider>
        </Router>
    );
}

Website.propTypes = {
    basename: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    lang: PropTypes.string,
};
