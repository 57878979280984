import { useCallback, useEffect } from 'react';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';

import { isMatchedById } from '@mssgme/shared';
import { useWebsiteData } from '../hooks';
import { useFrontAPI } from './useFrontAPI';
import { createSynchronizedStorage } from './useSynchronizedStorage';

// TODO: redo all

const { useStorageItem, useBatchUpdate } = createSynchronizedStorage();

export const useUserOrders = () => {
    return useSelector((state) => state.orders) || [];
};

const notEqual = (value) => (other) => other !== value;

export const useSavedOrdersIds = () => {
    const { _id } = useWebsiteData();
    const currentOrderKey = `currentOrder_${_id}`;
    const userOrdersKey = `userOrders_${_id}`;
    const [currentOrderId, setCurrentOrderId] = useStorageItem(currentOrderKey, null);
    const [userOrdersIds, setUserOrdersIds] = useStorageItem(userOrdersKey, [], { transform: JSON });

    const saveOrderId = useBatchUpdate((id) => {
        setCurrentOrderId(id);
        setUserOrdersIds((old) => [id, ...old.filter(notEqual(id))]);
    });
    const removeOrderId = useBatchUpdate((id) => {
        setCurrentOrderId(null);
        setUserOrdersIds((old) => old.filter(notEqual(id)));
    });
    const removeCurrentOrderId = useBatchUpdate(() => setCurrentOrderId(null));

    return {
        currentOrderId,
        userOrdersIds,
        saveOrderId,
        removeOrderId,
        removeCurrentOrderId,
    };
};

export const useSavedOrders = () => {
    const { currentOrderId, userOrdersIds, saveOrderId, removeOrderId, removeCurrentOrderId } = useSavedOrdersIds();
    const api = useFrontAPI();
    const userOrders = useUserOrders() || [];
    const currentOrder = userOrders.find(isMatchedById(currentOrderId));
    const loadUserOrders = useCallback(
        () => (userOrdersIds.length || userOrders.length) && api.orders.list({ query: { orders: userOrdersIds } }),
        [userOrdersIds, userOrders.length]
    );
    const shouldClearCurrentOrder = currentOrder?.finalized && userOrdersIds.includes(currentOrder._id);

    useEffect(() => {
        if (shouldClearCurrentOrder) {
            removeCurrentOrderId();
        }
    }, [shouldClearCurrentOrder]);

    useEffect(() => void loadUserOrders(), []);

    return {
        currentOrderId,
        userOrdersIds,
        currentOrder,
        userOrders,
        saveOrderId,
        removeOrderId,
        removeCurrentOrderId,
    };
};

export const useViewOrder = (orderId) => {
    const api = useFrontAPI();
    const { currentOrder, currentOrderId, removeCurrentOrderId } = useSavedOrders();
    const { value: order, error } = useAsync(() => api.orders.show({ orderId, query: { complete: true } }), [orderId]);
    const storedOrderId = currentOrderId || currentOrder?._id;
    const shouldClear =
        order && order?.finalized && storedOrderId === order?._id && ['pending', 'paid'].includes(order.status);

    useEffect(() => {
        if (shouldClear) {
            removeCurrentOrderId();
        }
    }, [shouldClear]);

    return {
        order,
        error,
    };
};
