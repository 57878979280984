import { isMatchedById } from '@mssgme/shared';
import { storefrontApiActions } from '../actions';

function ordersReducer(state = [], action) {
    switch (action.type) {
        case storefrontApiActions.orders.list.successType:
            return action.result;
        case storefrontApiActions.orders.create.successType:
            return [action.result, ...state];
        case storefrontApiActions.orders.remove.successType:
            return state.filter((order) => order._id !== action.orderId);
        case storefrontApiActions.orders.show.successType:
        case storefrontApiActions.orders.update.successType:
        case storefrontApiActions.orders.modify.successType:
        case storefrontApiActions.orders.finalize.successType:
        case storefrontApiActions.orders.cancel.successType: {
            const index = state.findIndex(isMatchedById(action.result._id));

            if (index < 0) {
                return [...state, action.result];
            }

            state[index] = action.result;

            return state.slice();
        }
        default:
            return state;
    }
}

export default ordersReducer;
