import { colorThemeFactory } from './colorTheme';

export const khakiTheme = colorThemeFactory({
    id: 'khakiTheme',
    name: 'Khaki',
    colors: {
        background: '#fffed6ff',
        panel: '#cdcca2ff',
        text: '#304026ff',
        dividerColor: '#3040264d',
        buttonBackground: '#304026ff',
        buttonTextColor: '#fffed6ff',
        featureBackgroundColor: '#cdcca2ff',
        featureTextColor: '#fffed6ff',
        productsCategoryTextColor: '#fffed6ff',
    },
});
