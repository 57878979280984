import { colorThemeFactory } from './colorTheme';

export const pinkTheme = colorThemeFactory({
    id: 'pinkTheme',
    name: 'Pink',
    colors: {
        background: '#282fbeff',
        panel: '#ff3265ff',
        text: '#ffffffff',
        dividerColor: '#ff3265ff',
        buttonBackground: '#ff3265ff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#242babff',
        featureTextColor: '#ffffffff',
        productsCategoryTextColor: '#ffffffff',
    },
});
