import {
    FiDollarSign,
    FiCheck,
    FiShoppingCart,
    FiGlobe,
    FiStar,
    FiPhoneCall,
    FiBookmark,
    FiGift,
    FiArrowRight,
    FiArrowUp,
} from 'react-icons/fi';
import { MdHelpOutline, MdFileDownload } from 'react-icons/md';

export default {
    call: {
        icon: FiPhoneCall,
        color: '#333333',
        match: /^tel:/,
    },
    star: {
        icon: FiStar,
        color: '#333333',
    },
    bookmark: {
        icon: FiBookmark,
        color: '#333333',
    },
    gift: {
        icon: FiGift,
        color: '#333333',
    },
    dollar: {
        icon: FiDollarSign,
        color: '#333333',
    },
    download: {
        icon: MdFileDownload,
        color: '#333333',
    },
    arrow_right: {
        icon: FiArrowRight,
        color: '#333333',
    },
    arrow_up: {
        icon: FiArrowUp,
        color: '#333333',
    },
    checkmark: {
        icon: FiCheck,
        color: '#333333',
    },
    shopping_cart: {
        icon: FiShoppingCart,
        color: '#333333',
    },
    help: {
        icon: MdHelpOutline,
        color: '#333333',
    },
    globe: {
        icon: FiGlobe,
        color: '#333333',
    },
};
