const strictEqual = (a, b) => a === b;

export default function isSameArray(a, b, { ordered, isEqual = strictEqual } = {}) {
    if (a.length === b.length) {
        if (ordered) {
            return !b.some((item, index) => !isEqual(a[index], item));
        } else {
            return !b.some((itemB) => !a.some((itemA) => isEqual(itemA, itemB)));
        }
    }

    return false;
}
