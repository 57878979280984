import { isEqual } from 'lodash';

export default function computeDiff(oldData, newData) {
    const oldObject = oldData && typeof oldData === 'object';
    const newObject = newData && typeof newData === 'object';

    if (newObject && oldObject) {
        const isArray = Array.isArray(oldData) && Array.isArray(newData);
        const diff = isArray ? [] : {};
        const iterable = isArray ? newData.keys() : Object.keys(newData);

        for (const key of iterable) {
            const o = oldData[key];
            const n = newData[key];

            if (!isEqual(o, n)) {
                diff[key] = computeDiff(o, n);
            }
        }

        return diff;
    }

    return newData;
}
