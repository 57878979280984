import { colorThemeFactory } from './colorTheme';

export const flatTheme = colorThemeFactory({
    id: 'flatTheme',
    name: 'Flat',
    colors: {
        background: '#fff5daff',
        panel: '#ff6b71ff',
        text: '#000000ff',
        dividerColor: '#ff6b71ff',
        buttonBackground: '#ff6b71ff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#f0e6cbff',
        featureTextColor: '#000000ff',
        productsCategoryTextColor: '#000000ff',
    },
});
