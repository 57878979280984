import { useLayoutEffect } from 'react';

export const useAnalyticsBridge = (pageId) => {
    useLayoutEffect(() => {
        if (!window.msa) {
            // not present in the VisualBuilder or if blocked by ad-blocker
            return;
        }

        window.msa('view', pageId);

        const handler = (e) => {
            /**
             * @type {Element}
             */
            let el = e.target;

            while (el) {
                if (el === document.body) {
                    return;
                }

                if (el.getAttribute('data-element') === 'custom-link') {
                    // `document.addEventListener` is deferred, so for internal link, this handler is triggered
                    // after the current page already changed, thus pageId would refer to the
                    // target page, not the click source page
                    const entityId = el.getAttribute('data-page-id') || pageId;
                    window.msa('click_link', entityId, { category: el.getAttribute('data-id') });

                    break;
                }

                const messenger = el.getAttribute('data-messenger') || el.getAttribute('data-product-messenger');

                if (messenger) {
                    window.msa('click_messenger', pageId, { category: messenger });

                    break;
                }

                el = el.parentNode;
            }
        };

        document.addEventListener('click', handler);

        return () => {
            document.removeEventListener('click', handler);
        };
    }, [pageId]);
};
