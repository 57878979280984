export default function(ua) {
    const normalized = (ua || '').toLowerCase();

    const Android = normalized.includes('android');
    const BlackBerry = normalized.includes('blackberry');
    const iOS = /iphone|ipad|ipod/i.test(normalized);
    const OperaMini = normalized.includes('opera mini');
    const IEMobile = normalized.includes('iemobile');
    const Safari = normalized.includes('safari');
    const WebView = normalized.includes('wv');
    const isMobile = Android || BlackBerry || iOS || OperaMini || IEMobile;

    return {
        Android,
        BlackBerry,
        iOS,
        OperaMini,
        IEMobile,
        Safari,
        WebView,
        isMobile,
    };
}
