import queryString from '../../helpers/queryString';

export default class Link {
    constructor(ua, messenger, params = {}) {
        this._ua = ua;
        this._name = messenger.name;
        this._bot = messenger.type === 'public';
        this._secret = messenger.type === 'secret';
        this._community = messenger.type === 'community';
        this._redirectUrl = messenger.redirectUrl;
        this._params = {};
        this._auto = messenger.type === 'auto';

        for (let prop in params) {
            if (this.isSupported(prop)) {
                this.addParam(prop, params[prop]);
            }
        }
    }

    isSupported(param) {
        return false;
    }

    addParam(param, value) {
        if (!this.isSupported(param)) throw new Error(`Unsupported param "${param}"`);

        this._params[param] = value;
    }

    removeParam(param) {
        delete this._params[param];
    }

    getUrl() {}

    getRedirectUrl() {
        return this._redirectUrl + queryString(this._params);
    }

    getPlatform() {}
}
