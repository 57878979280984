import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScrollTo } from '../../hooks';

export const PageContentWrapper = ({ children, haveScrollToBottom = false, setHaveScrollToBottom = null, ...rest }) => {
    const { hash } = useLocation();
    const [contentRef, scrollTo] = useScrollTo();

    useEffect(() => {
        // scroll to hash with delay on first page content load
        if (hash) {
            // why delay?: on load products and images shifts layout height and scrolls to wrong position
            scrollTo(hash, 'smooth', 600);
        }
    }, []);

    useEffect(() => {
        if (haveScrollToBottom && setHaveScrollToBottom) {
            scrollTo('bottom');
            setHaveScrollToBottom(false);
        }
    }, [haveScrollToBottom, setHaveScrollToBottom]);

    return <div ref={contentRef} { ...rest }>{children}</div>;
};

PageContentWrapper.propTypes = {
    haveScrollToBottom: PropTypes.bool,
    setHaveScrollToBottom: PropTypes.func,
};
