import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';

import { CoreContext, makeCoreRouter } from '../../contexts';

export function CoreContextProvider({ store, base = '/:pageSlug', pageSlug, children }) {
    const router = useRef();

    const context = useMemo(() => {
        router.current = null;

        const ctx = {
            store,
            pageSlug,
            path: base,
            get router() {
                return router.current || (router.current = makeCoreRouter(ctx));
            },
        };

        return ctx;
    }, [base, pageSlug]);

    return <CoreContext.Provider value={context}>{children}</CoreContext.Provider>;
}

CoreContextProvider.propTypes = {
    store: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    base: PropTypes.string,
    pageSlug: PropTypes.string.isRequired,
};
