import { colorThemeFactory } from './colorTheme';

export const cherryTheme = colorThemeFactory({
    id: 'cherryTheme',
    name: 'Cherry',
    colors: {
        background: '#ff4653ff',
        panel: '#ff5764ff',
        text: '#ffffffff',
        dividerColor: '#ffffffff',
        buttonBackground: '#9b1b3bff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#ff5764ff',
        featureTextColor: '#ffffffff',
        productsCategoryTextColor: '#ffffffff',
    },
});
