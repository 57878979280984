import removeUrlProtocol from './removeUrlProtocol';

const regex = /^(?:\w+:\/*)?([^:/$]+)(?::\d+)?(.*)$/i;

export default (url) => {
    const match = url.match(regex);

    if (match) {
        return `${match[1]}${match[2]}`;
    }

    return removeUrlProtocol(url);
};
