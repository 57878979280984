import viber from '../icons/messengers/viber.svg';
import vk from '../icons/messengers/vk.svg';
import telegram from '../icons/messengers/telegram-new.svg';
import line from '../icons/messengers/skype.svg';
import skype from '../icons/messengers/skype.svg';
import whatsapp from '../icons/messengers/whatsapp.svg';
import messenger from '../icons/messengers/messenger_new.svg';
import instagram from '../icons/messengers/instagram.svg';

export default {
    viber,
    vk,
    telegram,
    line,
    skype,
    whatsapp,
    messenger,
    instagram,
};
