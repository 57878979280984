import { useMemo } from 'react';
import { useParams } from 'react-router';

import { isMatchedBy } from '@mssgme/shared';
import { useHomePageData, useWebsiteData } from '../../hooks';

export const useCurrentPage = () => {
    const { pageSlug } = useParams();
    const website = useWebsiteData();
    const homePage = useHomePageData(website);
    const page = useMemo(() => {
        if (pageSlug) {
            const found = website.pages.find(isMatchedBy(pageSlug, 'slug'));

            if (found) {
                return found;
            }
        }

        return homePage;
    }, [pageSlug, website.pages]);

    return {
        website,
        homePage,
        page,
    };
};
