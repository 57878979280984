import { useCallback, useState } from 'react';

/**
 * @param {boolean} [initial] Initial state
 * @returns {[boolean, Function<void>, Function<void>, Function<void>]} State and handlers (<code>[state, true, false, toggle]</code>)
 */
export function useBooleanHandler(initial = false) {
    const [state, set] = useState(initial);

    const handleTrue = useCallback(() => set(true), []);
    const handleFalse = useCallback(() => set(false), []);
    const handleToggle = useCallback((arg) => set(typeof arg === 'boolean' ? arg : (old) => !old), []);

    return [state, handleTrue, handleFalse, handleToggle];
}
