/* eslint-disable import/namespace */
import * as links from './links';
// eslint-disable-next-line no-unused-vars
import { default as Link } from './links/link';

/**
 * @param {import('@mssgme.helpers').ua} ua User agent helper
 * @returns {function(messenger, params): Link} Link generator for provided messenger
 */
export const deepLinkGenerator = (ua) => {
    return (messenger, params) => {
        const platform = messenger.messenger;
        if (!links[platform]) throw new Error('Unsupported platform');

        return new links[platform](ua, messenger, params);
    };
};
