import { isFormData, isObject, JSON_CONTENT_TYPE } from './helpers';

export function setContentType(request) {
    if (isFormData(request.payload)) {
        delete request.headers['Content-Type'];
    } else {
        request.headers['Content-Type'] = JSON_CONTENT_TYPE;
    }
}

export function preparePayload(request) {
    request.originalPayload = request.payload;

    if (!isFormData(request.payload) && isObject(request.payload)) {
        request.payload = JSON.stringify(request.payload);
    }
}
