import React from 'react';

import { NotificationsBag, ThemeContext } from '@mssgme/ui';

import { useAutoTheme } from '../hooks';
import { PastDueModal } from '../UI';
import { Page } from '../Page';
import { useCurrentPage } from './useCurrentPage';

export const PageContainer = () => {
    const { website, homePage, page } = useCurrentPage();
    const { theme } = useAutoTheme(homePage.theme);

    return (
        <ThemeContext.Provider value={theme}>
            <NotificationsBag/>

            <Page
                website={website}
                page={page}
                footer={website.unpublishedAt && <PastDueModal/>}
            />
        </ThemeContext.Provider>
    );
};
