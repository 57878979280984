import { colorThemeFactory } from './colorTheme';

export const berriesTheme = colorThemeFactory({
    id: 'berriesTheme',
    name: 'Berries',
    colors: {
        background: '#150941ff',
        panel: '#261a52ff',
        text: '#ffffffff',
        dividerColor: '#ffffffff',
        buttonBackground: '#f90040ff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#261a52ff',
        featureTextColor: '#ffffffff',
        productsCategoryTextColor: '#ffffffff',
    },
});
