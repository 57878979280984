import TinyColor from 'tinycolor2';
import { isLightColor } from '@mssgme/helpers';

import { usePageTheme } from './useWebsiteTheme';

export const useContrastingPageColor = () => {
    const {
        theme: { colors, backgroundImage, backgroundImageOpacity },
        style: { backgroundColor: bgColor },
    } = usePageTheme(true, true);
    const bgAlpha = TinyColor(bgColor).getAlpha();
    const bgHasOpacity = bgAlpha < 1;
    const backgroundColor = bgHasOpacity ? TinyColor.mix(bgColor, '#fff', 100 - bgAlpha * 100).toHexString() : bgColor;
    const isLightBG = isLightColor(backgroundColor);
    const isLightContrasting = isLightColor(colors.buttonBackground);
    const isBgUntouched = TinyColor.equals(backgroundColor, colors.background);
    const hasImage = !!backgroundImage && backgroundImageOpacity > 0;

    if (isBgUntouched && !(isLightBG && isLightContrasting)) {
        return {
            color: colors.text,
            backgroundColor,
            hasImage,
            isLightBG,
        };
    }

    return {
        color: isLightBG ? '#333' : '#eee',
        backgroundColor,
        hasImage,
        isLightBG,
    };
};

export const useBasePageColor = (percent = 10) => {
    const {
        style: { backgroundColor },
    } = usePageTheme(true, true);
    const color = new TinyColor(backgroundColor);
    const isLightBG = isLightColor(color);

    return {
        isLightBG,
        backgroundColor,
        backgroundDimmed: (isLightBG ? color.darken(percent) : color.lighten(percent)).toHexString(),
    };
};
