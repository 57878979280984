import * as React from 'react';
import { IconBase } from 'react-icons';
function ArrowRightIcon(props, svgRef) {
    return (
        <IconBase fill="none" viewBox="0 0 24 24" ref={svgRef} {...props}>
            <path
                d="M5 12h14M12 5l7 7-7 7"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </IconBase>
    );
}
const ForwardRef = React.forwardRef(ArrowRightIcon);
export default ForwardRef;
