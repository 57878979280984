import { useLayoutEffect, useRef, useState, useMemo } from 'react';

import { mergeRefs } from '@mssgme/helpers';

export const useMergedRefs = (refs) => {
    return useMemo(() => mergeRefs(refs), refs);
};

export const useMultiRef = (length, handler) => {
    const cb = useRef(handler);
    const refs = useRef([]);
    const callback = (index, ref) => {
        refs.current[index] = ref;

        cb.current && cb.current(refs.current);
    };
    const [setters, setSetters] = useState(() => Array.from({ length }, (_, index) => callback.bind(null, index)));

    cb.current = handler;

    useLayoutEffect(() => {
        refs.current = Array.from({ length }, (_, index) => refs.current[index] || null);

        setSetters(refs.current.map((_, index) => callback.bind(null, index)));
    }, [length]);

    return setters;
};
