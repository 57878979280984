import { lightTheme } from './light';
import { darkTheme } from './dark';
import { pinkTheme } from './pink';
import { taxiTheme } from './taxi';
import { flatTheme } from './flat';
import { neonTheme } from './neon';
import { sweetTheme } from './sweet';
import { strongTheme } from './strong';
import { cherryTheme } from './cherry';
import { creamTheme } from './cream';
import { pastelTheme } from './pastel';
import { acidTheme } from './acid';
import { berriesTheme } from './berries';
import { tingeTheme } from './tinge';
import { tealTheme } from './teal';
import { bwTheme } from './blackOnWhite';
import { wbTheme } from './whiteOnBlack';
import { khakiTheme } from './khaki';

export { lightTheme, darkTheme, pinkTheme, taxiTheme, flatTheme, neonTheme, sweetTheme, strongTheme, khakiTheme };

export const ColorThemes = {
    [bwTheme.id]: bwTheme,
    [wbTheme.id]: wbTheme,
    [lightTheme.id]: lightTheme,
    [darkTheme.id]: darkTheme,
    [strongTheme.id]: strongTheme,
    [taxiTheme.id]: taxiTheme,
    [pinkTheme.id]: pinkTheme,
    [cherryTheme.id]: cherryTheme,
    [neonTheme.id]: neonTheme,
    [flatTheme.id]: flatTheme,
    [sweetTheme.id]: sweetTheme,
    [creamTheme.id]: creamTheme,
    [pastelTheme.id]: pastelTheme,
    [acidTheme.id]: acidTheme,
    [berriesTheme.id]: berriesTheme,
    [tingeTheme.id]: tingeTheme,
    [tealTheme.id]: tealTheme,
    [khakiTheme.id]: khakiTheme,
};

export const DEFAULT_COLOR_THEME = Object.keys(ColorThemes)[0];

export const getColorThemeById = (id, fallback = ColorThemes[DEFAULT_COLOR_THEME].theme) => {
    const definition = id && ColorThemes[id];

    return definition ? definition.theme : fallback;
};
