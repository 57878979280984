import { colorThemeFactory } from './colorTheme';

export const lightTheme = colorThemeFactory({
    id: 'lightTheme',
    name: 'Classic Light',
    colors: {
        background: '#ffffffff',
        panel: '#f7f7fAff',
        text: '#333333ff',
        dividerColor: '#ededf0ff',
        buttonBackground: '#f7f7faff',
        buttonTextColor: '#2979ffff',
        featureBackgroundColor: '#f7f7faff',
        featureTextColor: '#333333ff',
        productsCategoryTextColor: '#333333ff',
    },
});
