export const styleThemeFactory = ({ id, name }) => ({
    id,
    name,
    theme: {
        styleId: id,
        page: {
            backgroundImage: null,
            backgroundImageOpacity: 1,
            alignment: 'top',
        },
        blocks: {
            // do not remove: block styling auto-generation (empty styling can be removed)
            textrich: {},
            timer: {},
            giphy: {},
            customcode: {},
            subscriptionform: {
                gridMode: 'list',
                borderRadius: 8,
                units: {
                    borderRadius: 'px',
                },
            },
            servicesgrid: {
                gridMode: 'grid',
                borderRadius: 10,
                units: {
                    borderRadius: 'px',
                },
            },
            custom: {
                borderRadius: 0,
                units: {
                    borderRadius: 'px',
                },
            },
            products: {
                filterMode: 'tabs',
                gridMode: 'grid',
                borderRadius: 30,
                units: {
                    borderRadius: 'px',
                },
            },
            hat: {
                headerMode: 'avatar',
                align: 'center',
                padding: 'no',
                borderRadius: 16,
                units: {
                    borderRadius: 'px',
                },
            },
            divider: {
                template: 'line',
                margin: 'medium',
                width: 100,
                height: 2,
                size: 'small',
                alignment: 'center',
                units: {
                    width: '%',
                    height: 'px',
                },
            },
            heading: {
                size: 'large',
                textAlign: 'left',
                textStyle: ['bold'],
            },
            text: {
                fontSize: '1em',
                textAlign: 'left',
                textStyle: [],
            },
            video: {
                ratio: 'rectangle',
                borderRadius: 16,
                padding: 'yes',
                units: {
                    borderRadius: 'px',
                },
            },
            videogallery: {
                ratio: 'rectangle',
                padding: true,
                autoplay: 0,
                effect: '',
                borderRadius: 16,
                units: {
                    borderRadius: 'px',
                },
            },
            image: {
                ratio: 'original',
                fit: 'fill',
                borderRadius: 16,
                width: 100,
                alignment: 'center',
                padding: true,
                units: {
                    borderRadius: 'px',
                    width: '%',
                },
            },
            imagegallery: {
                ratio: 'rectangle',
                padding: true,
                perView: 1,
                autoplay: 0,
                effect: '',
                borderRadius: 16,
                units: {
                    borderRadius: 'px',
                },
            },
            link: {
                gradient: false,
                icon: null,
                width: 'full',
                borderRadius: 14,
                urlSubtitle: false,
                padding: true,
                units: {
                    borderRadius: 'px',
                },
            },
            messengers: {
                messengersMode: 'beefy',
                borderRadius: 10,
                padding: true,
                units: {
                    borderRadius: 'px',
                },
            },
            accordion: {
                fontSize: '1em',
                textStyle: [],
                exclusive: true,
                iconAlignment: 'right',
            },
            quote: {
                template: 'line',
                fontSize: '1em',
            },
            audio: {
                borderRadius: 16,
                padding: 'yes',
                units: {
                    borderRadius: 'px',
                },
            },
            list: {
                textStyle: [],
                listLabel: 'dot',
            },
            socials: {
                size: 'large',
                alignment: 'center',
            },
        },
    },
});
