import { isSameArray } from '@mssgme/helpers';

export const diffTheme = (oldObject, newObject) => {
    if (Array.isArray(newObject)) {
        return [...newObject];
    }

    const aggregated = {};

    for (const [key, value] of Object.entries(newObject)) {
        const template = oldObject[key];

        if (template && value && typeof value === 'object') {
            const resolved = diffTheme(template, value);

            if (Array.isArray(resolved)) {
                if (!isSameArray(template, resolved)) {
                    aggregated[key] = resolved;
                }
            } else if (Object.keys(resolved).length) {
                aggregated[key] = resolved;
            }
        } else if (template !== value) {
            aggregated[key] = value;
        }
    }

    return aggregated;
};
