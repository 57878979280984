import * as React from 'react';
import { IconBase } from 'react-icons';
function MssgmeLogoIcon(props, svgRef) {
    return (
        <IconBase viewBox="0 0 29 29" fill="none" ref={svgRef} {...props}>
            <path
                d="M5.76 3.242c0-.767.645-1.39 1.44-1.39.795 0 1.44.623 1.44 1.39v12.97c0 .767-.645 1.389-1.44 1.389-.795 0-1.44-.622-1.44-1.39V3.243zm11.52 1.853c0-.767.644-1.39 1.44-1.39.795 0 1.44.623 1.44 1.39v12.97c0 .767-.645 1.389-1.44 1.389-.796 0-1.44-.622-1.44-1.39V5.095zM11.52 1.39c0-.768.645-1.39 1.44-1.39.795 0 1.44.622 1.44 1.39v12.969c0 .767-.645 1.39-1.44 1.39-.795 0-1.44-.623-1.44-1.39V1.389zM26.735 19.59c.716-.333 1.577-.042 1.922.65.345.691.044 1.521-.673 1.854L15.83 27.743c-5.493 2.552-12.091.324-14.736-4.977A10.345 10.345 0 010 18.144V7.874c0-.767.645-1.39 1.44-1.39.795 0 1.44.623 1.44 1.39v10.27c0 1.183.276 2.35.808 3.416 1.955 3.919 6.831 5.566 10.892 3.679l12.155-5.648z"
                fill="currentColor"
            />
        </IconBase>
    );
}
const ForwardRef = React.forwardRef(MssgmeLogoIcon);
export default ForwardRef;
