import React, { useContext, useMemo } from 'react';

import { Theme } from '../components';

export { mergeTheme, getThemeById, ColorThemes } from '@mssgme/shared';

export const ThemeContext = React.createContext({});

export function useTheme() {
    return useContext(ThemeContext);
}

/**
 * @param {*} options Options
 * @param {object} options.theme Element theme
 * @param {object} options.baseTheme Theme to take defaults from
 * @param {string} options.elementPath Path to element theme in element theme object
 * @param {boolean} options.inherit Should be other values from base theme prepended to resolved theme
 * @param {boolean} [options.compile] Compile resolved theme to styles
 * @returns {{ elementPath: string, theme: object, style: boolean|object }} Resolved theme
 */
export function resolveElementTheme({ theme, baseTheme, elementPath, inherit, compile = true }) {
    const resolved = Theme.resolve(elementPath, baseTheme, theme);
    const combined = inherit ? { ...baseTheme, ...resolved } : resolved;
    const style = compile && Theme.compile(combined);

    return {
        elementPath,
        theme: combined,
        style,
    };
}

/**
 * @param {*} options Options
 * @param {object} options.theme Element theme
 * @param {object} options.baseTheme Theme to take defaults from
 * @param {string} options.elementPath Path to element theme in element theme object
 * @param {boolean} options.inherit Should be other values from base theme prepended to resolved theme
 * @param {boolean} [options.compile] Compile resolved theme to styles
 * @returns {{ elementPath: string, theme: object, style: boolean|object }} Resolved theme
 */
export function useElementTheme(options) {
    return useMemo(() => resolveElementTheme(options), [
        options.compile,
        options.inherit,
        options.elementPath,
        options.baseTheme,
        options.theme,
    ]);
}
