import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const syncLoad = async (node) => {
    const scripts = Array.from(node.querySelectorAll('script'));
    const load = (script) =>
        new Promise((resolve, reject) => {
            const clone = document.createElement('script');

            Array.from(script.attributes).forEach((attribute) => {
                clone.setAttribute(attribute.name, attribute.value);
            });

            clone.onerror = reject;

            if (clone.src) {
                clone.onload = resolve;
            } else {
                clone.text = script.text;

                // there is no 'onload' event for inline scripts, the closest is just waiting for the next render cycle
                // if script eval failed, the promise would be rejected before `resolve` would be called
                window.requestAnimationFrame(() => {
                    resolve();
                    clone.dataset.evaluated = true;
                });
            }

            script.parentNode?.replaceChild(clone, script);
        });

    for (const script of scripts) {
        try {
            await load(script);
        } catch (e) {
            console.log(e.message);
        }
    }
};

export function InnerHTMLWithScripts(props) {
    const { html, ...rest } = props;
    const divRef = useRef(null);

    useEffect(() => {
        if (!divRef.current) return;

        void syncLoad(divRef.current);
    }, [divRef.current]);

    return <div {...rest} ref={divRef} dangerouslySetInnerHTML={{ __html: html }} />;
}

InnerHTMLWithScripts.propTypes = {
    html: PropTypes.string,
};
