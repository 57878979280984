import { colorThemeFactory } from './colorTheme';

export const pastelTheme = colorThemeFactory({
    id: 'pastelTheme',
    name: 'Pastel',
    colors: {
        background: '#fda49fff',
        panel: '#ffaea5ff',
        text: '#000000ff',
        dividerColor: '#000000ff',
        buttonBackground: '#101357ff',
        buttonTextColor: '#ffffffff',
        featureBackgroundColor: '#ffaea5ff',
        featureTextColor: '#000000ff',
        productsCategoryTextColor: '#000000ff',
    },
});
