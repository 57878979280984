export function bindActionCreator(actionCreator, dispatch) {
    return function(...args) {
        return dispatch(actionCreator(...args));
    };
}

export function deepBindActionCreators(actionCreators, dispatch) {
    if (typeof actionCreators === 'object') {
        const boundActionCreators = {};

        for (const [prop, value] of Object.entries(actionCreators)) {
            boundActionCreators[prop] = deepBindActionCreators(value, dispatch);
        }

        return boundActionCreators;
    } else if (typeof actionCreators === 'function') {
        return bindActionCreator(actionCreators, dispatch);
    } else {
        throw new Error('Invalid actionCreators type');
    }
}
