import { useMemo } from 'react';
import { Link as NavLink, useLocation } from 'react-router-dom';

import { LinkBlank } from '@mssgme/ui';
import { isMatchedById } from '@mssgme/shared';
import { getPageName } from '../lib';

import { useCoreContext, usePageData, useWebsiteData } from '../../hooks';

const generateUrl = (value, type) => {
    if (!value) {
        return '';
    }

    switch(type) {
        case 'tel':
            return 'tel:' + value;
        case 'email':
            return 'mailto:' + value;
        case 'sms':
            return 'sms:' + value;
        default:
            return value;
    }
};

const getTag = (actionType) => {
    switch(actionType) {
        case 'fullImage':
        case 'nothing':
            return 'div';
        case 'page':
        case 'anchor':
            return NavLink;
        default:
            return LinkBlank;
    }
};

const getPageMeta = (pageId, isViewMode, website) => {
    const page = pageId && website.pages.find(isMatchedById(pageId));

    if (!page) {
        return {};
    }

    const isHomePage = website.homePageId === page._id;
    const slug = isHomePage ? '' : page.slug;

    return {
        _id: page._id,
        title: page.title,
        name: getPageName(page, isHomePage) || page.slug,
        url: website.finalUrl + (slug ? '/' + slug : '') + (page.draft ? `?draft=${page.draft}` : ''),
        slug: isViewMode ? pageId : slug,
        type: 'page',
    };
};

export const useAction = ({ action, isViewMode, interactive }) => {
    const location = useLocation();
    const website = useWebsiteData();
    const currentPage = usePageData();
    const { router } = useCoreContext();

    return useMemo(
        () => {
            const {
                title,
                name,
                url,
                slug,
                _id,
            } = getPageMeta(action.page || action.anchor?.page, isViewMode, website);
            const isInternal = ['page', 'anchor'].includes(action.type) && !!_id;
            const isDeletedPage = ['page', 'anchor'].includes(action.type) && !_id;
            const currentUrl = isInternal ? url : generateUrl(action.url, action.type);
            const hash = action.anchor?.block && action.type === 'anchor' && '#block-' + action.anchor?.block;
            const Tag = interactive ? getTag(action.type) : 'div';
            const tagAttributes = interactive
                ? isInternal
                    ? { to: router.toPage(slug, hash) }
                    : { href: currentUrl }
                : isDeletedPage
                    ? { to: '/' }
                    : {};
            // scrollToHash function emulates behaviour of native hash links, due to how react works.
            // scroll to hash without delay if on same page.
            const scrollToHash = hash && currentPage._id === action.anchor.page
                ? () => document.querySelector(hash).scrollIntoView({ behavior: 'smooth', block: 'start' })
                : null;

            return {
                pageMeta: { title, name },
                isInternal,
                currentUrl,
                Tag,
                tagAttributes,
                hash,
                scrollToHash,
            };
        },
        [action, isViewMode, interactive, website, currentPage, location.hash]
    );
};
