import React, { useMemo } from 'react';

import { TextRichBlockKind } from '@mssgme/shared';
import { LandingsPropTypes, RichTextView } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';

export default function TextRich({ block, style: baseStyle, ...rest }) {
    const { style } = useBlockTheme(block);
    const combined = useMemo(() => (baseStyle ? { ...style, ...baseStyle } : style), [style, baseStyle]);
    const attributes = useMemo(() => ({ dangerouslySetInnerHTML: { __html: block.html } }), [block.html]);

    return <BlockBase as={RichTextView} style={combined} {...attributes} {...rest} />;
}

TextRich.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

TextRich.defaultProps = BlockBase.defaultProps;

TextRich.kind = TextRichBlockKind;
