import * as React from 'react';
import { IconBase } from 'react-icons';
function ClipboardIcon(props, svgRef) {
    return (
        <IconBase fill="none" viewBox="0 0 24 24" ref={svgRef} {...props}>
            <path
                d="M16 4h2a2 2 0 012 2v14a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2h2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 2H9a1 1 0 00-1 1v2a1 1 0 001 1h6a1 1 0 001-1V3a1 1 0 00-1-1z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </IconBase>
    );
}
const ForwardRef = React.forwardRef(ClipboardIcon);
export default ForwardRef;
