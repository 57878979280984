import React, { useMemo } from 'react';

import { keyHash } from '@mssgme/helpers';
import { HatBlockKind } from '@mssgme/shared';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme, usePageTheme, useUploads, MediaSizes } from '../../../hooks';
import { BlockBase, useBlockHorizontalInsetsPadding } from '../BlockBase';

import HatCentered from './templates/HatCentered/HatCentered';
import Cover from './templates/Cover/Cover';
import HatBrand from './templates/HatBrand/HatBrand';
import HatAvatar from './templates/HatAvatar/HatAvatar';

export default function Hat({ block, style: baseStyle, insets, isMobileMode, ...rest }) {
    const { theme: pageTheme } = usePageTheme(false);
    const { theme, style } = useBlockTheme(block);
    const { getMediaSizeUrl } = useUploads();
    const bgUrl = getMediaSizeUrl(block.bgUrl, MediaSizes.Large);
    const logoUrl = getMediaSizeUrl(block.logoUrl, MediaSizes.Half);
    const withPadding = theme.padding === 'yes';
    const blockInsets = useBlockHorizontalInsetsPadding(insets, withPadding);
    const combined = useMemo(() => (baseStyle ? { ...style, ...baseStyle } : style), [style, keyHash(baseStyle)]);

    return (
        <BlockBase style={combined} insets={blockInsets} {...rest}>
            {theme.headerMode === 'image' && (
                <Cover blockStyles={style} coverSrc={bgUrl} hasPadding={withPadding} isMobileMode={isMobileMode} />
            )}
            {theme.headerMode === 'centered' && (
                <HatCentered
                    isMobileMode={isMobileMode}
                    blockStyles={style}
                    hasPadding={withPadding}
                    coverSrc={bgUrl}
                    logoSrc={logoUrl}
                    title={block.title}
                    subtitle={block.subtitle}
                    textColor={theme.textColor}
                    avatarBorderColor={pageTheme.backgroundColor}
                />
            )}
            {theme.headerMode === 'brand' && (
                <HatBrand
                    isMobileMode={isMobileMode}
                    blockStyles={style}
                    hasPadding={withPadding}
                    coverSrc={bgUrl}
                    logoSrc={logoUrl}
                    title={block.title}
                    subtitle={block.subtitle}
                    align={theme.align}
                    textColor={theme.textColor}
                    avatarBorderColor={pageTheme.backgroundColor}
                />
            )}
            {theme.headerMode === 'avatar' && (
                <HatAvatar
                    logoSrc={logoUrl}
                    title={block.title}
                    subtitle={block.subtitle}
                    align={theme.align}
                    textColor={theme.textColor}
                />
            )}
        </BlockBase>
    );
}

Hat.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Hat.defaultProps = BlockBase.defaultProps;

Hat.kind = HatBlockKind;
