import PropTypes from 'prop-types';
import React from 'react';

export function LinkBlank(props) {
    return <a target="_blank" rel="noopener noreferrer" {...props} />;
}

LinkBlank.propTypes = {
    href: PropTypes.string.isRequired,
};
