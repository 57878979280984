import { ApiClient } from '@mssgme/api-client';

const PATHS = {
    page: '/pages/:pageId',
    orders: '/workspaces/:workspaceId/orders',
    order: '/workspaces/:workspaceId/orders/:orderId',
};

export class FrontApiClient extends ApiClient {
    _params = {
        workspaceId: '',
    };

    resources = {
        orders: {
            create: this.put(`${PATHS.page}/orders`),
            show: this.get(PATHS.order),
            list: this.get(PATHS.orders),
            update: this.put(PATHS.order),
            modify: this.post(`${PATHS.order}/modify`),
            finalize: this.post(`${PATHS.order}/finalize`),
            cancel: this.post(`${PATHS.order}/cancel`),
            remove: this.post(`${PATHS.order}/remove`),
            signatures: {
                createStripePaymentIntent: this.post(`${PATHS.order}/signatures/stripe/payment-intents`),
                createWayforpaySignature: this.post(`${PATHS.order}/signatures/wayforpay/signatures`),
                createRobokassaSignature: this.post(`${PATHS.order}/signatures/robokassa/signatures`),
                createLiqpaySignature: this.post(`${PATHS.order}/signatures/liqpay/signatures`),
            },
        },
    };
}
